import axios from "axios";
import { useRoutes } from "react-router-dom";
import Themeroutes from "./routes/Router";

const App = () => {
  const routing = useRoutes(Themeroutes);
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // development build code
    console.log("development build code")
    axios.defaults.baseURL = 'https://back.bypyme.com';
    

} else {
    // production build code
    console.log("production build code")
    axios.defaults.baseURL = 'https://back.bypyme.com';

}


  return <div className="dark">{routing}</div>;
};

export default App;
